import React from "react";
import { AppColors } from "../../../data/AppColors";
import { Widget } from "../../general/widget/Widget";
import { Container } from "../../general/container/Container";
import { Paragraph } from "../../general/paragraph/Paragraph";
import { getDataForWidget } from "../Utils";
import { AppLoadingStates } from "../../../data/AppLoadingStates";
import { validateEndpoint } from "../../../helper/EndpointValidation";
import { apiSettings, kpiSettings, workSettings } from "../../../interfaces/User";
import { FormatDate } from "../../../helper/DateFormatter";
import { Loading } from "../../general/loading/Loading";
import { useContrastTextColor } from "../../../hooks/FontColorPicker";

interface props {
    background: AppColors
    headline: string
    kpiSettings?: kpiSettings
    apiSettings?: apiSettings
    animation: boolean
    refreshingRate: number // in ms
    workSettings?: workSettings
    suffix?: string
}

export const ValueWidget = ({ background, headline, kpiSettings, apiSettings, animation, refreshingRate, workSettings, suffix }: props) => {

    const [lastUpdated, setLastUpdated] = React.useState<string>("00.00")
    const [loading, setLoading] = React.useState<AppLoadingStates>(AppLoadingStates.LOADING)
    const [percentageLoading, setPercentageLoading] = React.useState<AppLoadingStates>(AppLoadingStates.LOADING)
    const [value, setValue] = React.useState<number>()
    const [percentage, setPercentage] = React.useState<number>(0)
    const [fontSize, setFontSize] = React.useState<string>("value")

    // this one is for re-rendering the widget
    const [animationKey, setAnimationKey] = React.useState<number>(0);

    const textColor = useContrastTextColor({ background });

    React.useEffect(() => {
        const getValue = async () => {
            if (!apiSettings) return setLoading(AppLoadingStates.CONFIG_ERROR_ENDPOINT)

            const result = await getDataForWidget("/widgets/api/" + apiSettings.module, "POST", {...apiSettings, widgettype: "ValueComp"})

            if (!result) return setLoading(AppLoadingStates.SERVER_ERROR)
            if (result === 450) return setLoading(AppLoadingStates.CONFIG_MISSING_PROP)
            if (result === 451) return setLoading(AppLoadingStates.CONFIG_ERROR_ENDPOINT)

            if (kpiSettings) {
                const kpiData = await getDataForWidget("/widgets/api/" + apiSettings.module, "POST", {...kpiSettings.apiSettings, widgettype: "ValueComp"})
                if (!kpiData) {
                    setPercentageLoading(AppLoadingStates.CONFIG_ERROR_ENDPOINT)
                } else {
                    console.log("kpi", kpiData.data)
                    if(kpiData.data === 0 || kpiData.data === "0") {
                        console.log("called for headline", headline, " new kpi: ", result.data * 100)
                        setPercentage(result.data * 100)
                    } else {
                        setPercentage(Number((((result.data - kpiData.data) / kpiData.data) * 100).toFixed(2)))
                    }
                    setPercentageLoading(AppLoadingStates.SUCCESS)
                }
            }

            if (!value) setAnimationKey((prevKey) => prevKey + 1)
            setValue(result.data) 
            setLoading(AppLoadingStates.SUCCESS)
            setLastUpdated(FormatDate(new Date()))
        }

        getValue()

        const intervalId = setInterval(() => {
            getValue()
        }, refreshingRate);

        return () => clearInterval(intervalId);
    }, [])

    return (
        <Widget background={background} animate={animation} height="100%" key={animationKey}>
            <Container display="flex" justify_content="center" flex_direction={true} padding="1em" height="50vh" align_items="center">
                <Container>
                    <Paragraph font_weight="600" color={textColor} text_align="center">{headline}</Paragraph>
                </Container>
                <Container height="33vh" overflow_y="auto" display="flex" justify_content="center" align_items="center" flex_direction={true}>
                    {loading === AppLoadingStates.SUCCESS ? (
                        <Container margin="0.8em 0 0 0" display="flex">
                            <Paragraph font_weight="800" text_align="center" classname={fontSize} color={textColor}>{value}</Paragraph>
                            {suffix && (
                                <Paragraph font_weight="800" classname={fontSize} color={textColor} margin="0 0 0 0.2em">{suffix}</Paragraph>
                            )}
                        </Container>
                    ) : (
                        <Container margin="2em 0 0 0">
                            <Paragraph font_weight="700" color={textColor} text_align="center" margin="0 0 0.8em 0">{loading}</Paragraph>
                            <Loading />
                        </Container>
                    )}
                    {(loading === AppLoadingStates.SUCCESS && workSettings?.estimatedWorkToBeDoneInOneHour) && (
                        <Container margin="8vh 0 0 0">
                            <Paragraph font_weight="700" color={textColor} text_align="center" font_size="0.7em" margin="0 0 0 0">Zeitaufwand</Paragraph>
                            <Paragraph font_weight="700" color={textColor} font_size="1.5em" text_align="center" margin="0 0 0 0">{Math.round((value! / workSettings.estimatedWorkToBeDoneInOneHour) * 100) / 100} Std</Paragraph>
                        </Container>
                    )}
                    {kpiSettings && (
                        <Container margin="2vh 0 0 0" display="flex" justify_content="center" flex_direction={true} align_items="center">
                            {percentageLoading === AppLoadingStates.SUCCESS ? (
                                <>
                                    <Container border_radius="20px" background={(kpiSettings.switchColors ? (percentage < 0 ? "#23d959" : percentage === 0 ? "#d4d3d2" : "#e34247") : (percentage > 0 ? "#23d959" : percentage === 0 ? "#d4d3d2" : "#e34247"))} width="9vw">
                                        <Paragraph color={textColor} font_weight="600" text_align="center">{percentage > 0 && "+"}{percentage} %</Paragraph>
                                    </Container>
                                    <Paragraph font_size="0.8em" color={textColor} text_align="center" margin="0.6em 0 0 0">Vergleich: {kpiSettings.text}</Paragraph>
                                </>
                            ) : (
                                <Container display="flex" justify_content="center">
                                    <Paragraph color={textColor} font_weight="600" text_align="center">{percentageLoading}</Paragraph>
                                </Container>
                            )}
                        </Container>
                    )}
                </Container>
                <Container margin_top="auto">
                    <Paragraph font_weight="600" text_align="center" classname="update" color={textColor}>Stand:</Paragraph>
                    <Paragraph font_weight="700" text_align="center" classname="update" color={textColor}>{lastUpdated} Uhr</Paragraph>
                </Container>
            </Container>
        </Widget>
    )
}