import React from "react"

import styles from "./Page.module.scss"
import { Navbar } from "./Navbar"

interface props {
    children: React.ReactNode
    background: string
    showNavbar: boolean
}

export const Page = ({children, background, showNavbar}: props) => {

    return (
        <div className={styles.page} style={{
            backgroundColor: background
        }}>
            {showNavbar && (
                <Navbar />
            )}
            <main>
                {children}
            </main>
        </div>
    )
}