import React from "react";
import { AppColors } from "../../../data/AppColors";
import { Widget } from "../../general/widget/Widget";
import { Container } from "../../general/container/Container";
import { Paragraph } from "../../general/paragraph/Paragraph";

import styles from "./ListWidget.module.scss"
import { getDataForWidget } from "../Utils";
import { AppLoadingStates } from "../../../data/AppLoadingStates";
import { apiSettings, listSettings } from "../../../interfaces/User";
import { FormatDate } from "../../../helper/DateFormatter";
import { Loading } from "../../general/loading/Loading";
import { isNumeric } from "../../../helper/Helper";
import { useContrastTextColor } from "../../../hooks/FontColorPicker";

interface props {
    background: AppColors
    headline: string
    apiSettings?: apiSettings
    listSettings: listSettings
    animation: boolean
    dateformat?: string
}

export const ListWidget = ({ background, headline, apiSettings, listSettings, animation, dateformat }: props) => {

    const [lastUpdated, setLastUpdated] = React.useState<string>("00.00.0000")
    const [loading, setLoading] = React.useState<AppLoadingStates>(AppLoadingStates.LOADING)
    const [value, setValue] = React.useState<any[]>([])

    const textColor = useContrastTextColor({ background });

    React.useEffect(() => {
        const getValue = async () => {
            if (!apiSettings || !(apiSettings.endpoint || apiSettings.headers)) return setLoading(AppLoadingStates.CONFIG_ERROR_ENDPOINT)

            const result = await getDataForWidget("/widgets/api/" + apiSettings.module, "POST", {
                endpoint: apiSettings.endpoint,
                responseKey: apiSettings.responseKey,
                widgettype: "ListComp",
                headers: apiSettings.headers
            })

            if (!result) return setLoading(AppLoadingStates.SERVER_ERROR)
            if (result === 450) return setLoading(AppLoadingStates.CONFIG_MISSING_PROP)
            if (result === 451) return setLoading(AppLoadingStates.CONFIG_ERROR_ENDPOINT)

            setValue(result.data)
            setLastUpdated(FormatDate(new Date()))
            setLoading(AppLoadingStates.SUCCESS)
        }

        getValue()
    }, [])


    const getAttributeToShow = (item: any, attribute: string) => {
        const split = attribute.toLowerCase().split(".")

        for (const key of split) {
            if (item.hasOwnProperty(key)) {
                item = item[key]
            } else {
                return setLoading(AppLoadingStates.CONFIG_MISSING_PROP)
            }
        }

        return item
    }

    const formatDate = (str: string) => {
        if(!dateformat) return str

        // check if date
        const parsedDate = new Date(str);
        if(isNaN(parsedDate.getTime())) return str

        switch (dateformat) {
            case "eu":
                const date = new Date(str);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = date.getFullYear();
                return `${day}.${month}.${year}`;

            default:
                setLoading(AppLoadingStates.CONFIG_MISSING_PROP)
                return str
        }
    }

    return (
        <Widget background={background} animate={animation} height="100%" overflow="hidden">
            <Container display="flex" justify_content="center" flex_direction={true} padding="1em 1em 1em 1em" height="100vh">
                <Container margin="3em 0 0 0">
                    <Paragraph font_weight="600" color={textColor} text_align="center">{headline}</Paragraph>
                </Container>
                {loading === AppLoadingStates.SUCCESS ? (
                    <Container margin="1.8em 0 0 0">
                        <ul className={styles.listwidget__list}>
                            {value.slice(0, listSettings!.length).map((item, index) => (
                                <li key={index}>
                                    {listSettings.attributes.map((line) => (
                                        <Container display="flex" justify_content="space-between" white_space="nowrap" overflow="hidden" textoverflow="ellipsis">
                                            <Paragraph font_weight="600" color={line.labelColor} font_size={(listSettings.attributes[0].fontSize ?? "list")}>{line.label} : </Paragraph>
                                            <Paragraph color={line.attributeColor} font_size={(listSettings.attributes[1].fontSize ?? "list")}>{(isNumeric(getAttributeToShow(item, line.attribute)) ? Math.round(getAttributeToShow(item, line.attribute) * 100) / 100 : formatDate(getAttributeToShow(item, line.attribute)))}</Paragraph>
                                        </Container>
                                    ))}
                                </li>
                            ))}
                        </ul>
                    </Container>
                ) : (
                    <Container margin="2em 0 0 0">
                        <Paragraph font_weight="600" color={textColor} text_align="center">{loading}</Paragraph>
                        <Loading />
                    </Container>
                )}

                <Container margin_top="auto">
                    <Paragraph font_weight="700" text_align="center" classname="update">Stand</Paragraph>
                    <Paragraph font_weight="700" text_align="center" classname="update">{lastUpdated}</Paragraph>
                </Container>
            </Container>
        </Widget>
    )
}