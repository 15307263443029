import React from "react"
import { AppColors } from "../../../data/AppColors"
import { Widget } from "../../general/widget/Widget"
import { Container } from "../../general/container/Container"
import { Paragraph } from "../../general/paragraph/Paragraph"

import styles from "./AreaChartWidget.module.scss"
import { AreaChart } from "./AreaChart"
import { apiSettings, chartSettings } from "../../../interfaces/User"
import { AppLoadingStates } from "../../../data/AppLoadingStates"
import { validateEndpoint } from "../../../helper/EndpointValidation"
import { getDataForWidget } from "../Utils"
import { Loading } from "../../general/loading/Loading"
import { isNumber } from "../../../helper/Helper"
import { FormatDate } from "../../../helper/DateFormatter"
import { useContrastTextColor } from "../../../hooks/FontColorPicker"

interface props {
    headline: string
    background: AppColors
    animation: boolean
    apiSettings?: apiSettings
    refreshingRate: number
    chartSettings?: chartSettings
}

export const AreaChartWidget = ({ headline, background, animation, apiSettings, refreshingRate, chartSettings }: props) => {

    const [loading, setLoading] = React.useState<AppLoadingStates>(AppLoadingStates.LOADING_TIME)
    const [values, setValues] = React.useState<number[]>([])

    const [valueLoading, setValueLoading] = React.useState<AppLoadingStates>(AppLoadingStates.LOADING)
    const [value, setValue] = React.useState<number | string>()

    const [lastUpdated, setLastUpdated] = React.useState<string>("00.00")

    // this one is for re-rendering the widget
    const [animationKey, setAnimationKey] = React.useState<number>(0);

    const textColor = useContrastTextColor({ background });

    React.useEffect(() => {
        const getValue = async () => {
            if (!apiSettings) return setValueLoading(AppLoadingStates.CONFIG_MISSING_PROP)

            const result = await getDataForWidget("/widgets/api/" + apiSettings.module, "POST", { ...apiSettings, widgettype: "areaComp" })

            console.log("res1", result)

            if (!result) return setValueLoading(AppLoadingStates.SERVER_ERROR)
            if (result === 450) return setValueLoading(AppLoadingStates.CONFIG_MISSING_PROP)
            if (result === 451) return setValueLoading(AppLoadingStates.CONFIG_ERROR_ENDPOINT)

            if (!value) setAnimationKey((prevKey) => prevKey + 1)
            setValue(result.data)
            setValueLoading(AppLoadingStates.SUCCESS)
            setLastUpdated(FormatDate(new Date()))
        }

        const intervalId = setInterval(() => {
            getValue()
        }, refreshingRate);

        return () => clearInterval(intervalId);
    }, [])

    React.useEffect(() => {
        const getValues = async () => {
            if (!apiSettings || !chartSettings!.interval) return setLoading(AppLoadingStates.CONFIG_MISSING_PROP)
                
            const result = await getDataForWidget("/widgets/chart/" + chartSettings?.apiSettings.module, "POST", {apiSettings: apiSettings, chartSettings: chartSettings})
            console.log("res", result.data)
            if (!result) return setLoading(AppLoadingStates.ERROR)
            if (isNumber(result)) {
                if (result === 451) {
                    setLoading(AppLoadingStates.CONFIG_MISSING_PROP)
                    return
                }
                // not every response from array was successfull
                return setLoading(AppLoadingStates.API_RESPONSE_MISSING)
            }

            setValues(result.data.reverse())
            setLoading(AppLoadingStates.SUCCESS)
        }

        getValues()
    }, [])

    return (
        <Widget background={background} position="relative" animate={animation} height="100%" width="100%">
            {loading === AppLoadingStates.SUCCESS ? (
                <>
                    <div className={styles.areachartwidget__chart}>
                        <AreaChart chartSettings={chartSettings!} data={values} color={textColor} />
                    </div>
                    <div className={styles.areachartwidget__overlay}>
                        <Container display="flex" justify_content="center" flex_direction={true} align_items="center">
                            <Container>
                                <Paragraph font_weight="600" color={textColor}>{headline}</Paragraph>
                            </Container>
                            <Container>
                                {valueLoading === AppLoadingStates.SUCCESS ? (
                                    <Paragraph font_weight="800" color={textColor}>{value}</Paragraph>
                                ) : (
                                    <Paragraph text_align="center" font_size="1em">{valueLoading}</Paragraph>
                                )}
                                
                            </Container>
                        </Container>
                    </div>
                </>

            ) : (
                <Container display="flex" justify_content="center" margin="3.1em 0 0 0">
                    <Paragraph font_weight="600" color="#fff" text_align="center">{loading}</Paragraph>
                    <Loading />
                </Container>
            )}
            <Container margin_top="auto">
                <Paragraph font_weight="700" text_align="center" classname="update" color={textColor} margin="0.6em 0 0 0">{lastUpdated} Uhr</Paragraph>
            </Container>
        </Widget>
    )
}