import { AppColors } from "../../../data/AppColors";
import { Container } from "../../general/container/Container";
import { Widget } from "../../general/widget/Widget";

interface props {
    background: AppColors
    source: string
    animation: boolean
}

export const ImageWidget = ({background, source, animation}: props) => {

    return (
        <Widget background={background} animate={animation} height="100%">
            <Container display="flex" justify_content="center" margin="6em 0 0 0" padding="1em">
                <img src={source} />
            </Container>
        </Widget>
    )
}