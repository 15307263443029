import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { chartSettings } from '../../../interfaces/User';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

interface props {
  chartSettings: chartSettings
  data: Array<number>
  color: string
}

export const AreaChart = ({ chartSettings, data, color }: props) => {

  const options1 = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: (chartSettings.title ? true : false),
        text: chartSettings.title,
        color: color // Schriftfarbe des Titels
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          color: color // Schriftfarbe der X-Achse
        }
      },
      y: {
        grid: {
          display: false,
          drawBorder: false
        },
        beginAtZero: true,
        ticks: {
          color: color // Schriftfarbe der Y-Achse
        }
      },
    },
  };

  const getLast7Weekdays = (): string[] => {
    const weekdays = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
    const today = new Date();
    const todayIndex = today.getDay(); // Index des heutigen Wochentags

    const result: string[] = [];
    for (let i = todayIndex + 1; i < todayIndex + 8; i++) {
      const dayIndex = i % 7;
      result.push(weekdays[dayIndex]);
    }

    return result;
  }


  const generateLabels = (): string[] => {
    switch (chartSettings.interval) {
      case "week": return getLast7Weekdays()
      case "month": return []
      default: return []
    }
  }

  const labels = generateLabels()

  const data1 = {
    labels,
    datasets: [
      {
        fill: true,

        data: data,
        backgroundColor: '#e3e3e3',
        borderWidth: 0,
        lineTension: 0.4
      },
    ],
  };

  return (
    <Line options={options1} data={data1} />
  )
}