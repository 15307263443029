import React from "react";
import styles from "./Grid.module.scss"
import { getFromSessionStorage } from "../../../helper/Sessionstorage";

interface props {
    children: React.ReactNode
}

export const Grid = ({children}: props) => {

    return (
        <div className={styles.grid} style={{
            height: getFromSessionStorage("showNavbar") ? "calc(100vh - 64px)" : "100vh"
        }}>
            {children}
        </div>
    )
}