import { server } from "../data/AppSettings"

export interface HttpRequest<REQB> {
    path: string
    method?: string
    body?: REQB
    credentials?: string
}

export interface HttpResponse<RESB> {
    ok: boolean
    body?: RESB
    status: number
}

export const http = async <RESB, REQB = undefined>(
    config: HttpRequest<REQB>
): Promise<HttpResponse<RESB> | undefined> => {
    const request = new Request(`${server}${config.path}`, {
        method: config.method || "get",
        headers: {
            "Content-Type": "application/json",
        },
        body: config.body ? JSON.stringify(config.body) : undefined,
        credentials: "include"
    })

    try {
        const response = await fetch(request)
        if (response.ok) {
            const body = await response.json()
            return { ok: response.ok, body, status: response.status }
        } else {
            return { ok: response.ok, status: response.status }
        }
    } catch (error) {
        // server does not respond
        return undefined
    }

}