export const isNumber = (val: any) => {
    if (typeof val === "number" && val === val) {
      const isIntegerOrDecimal = !Number.isNaN(val) && !Number.isNaN(parseFloat(String(val)));
      return isIntegerOrDecimal;
    }
    return false;
};

export const isNumeric = (str: string) => {
  return /^[0-9]+(\.[0-9]+)?$/.test(str);
}
  