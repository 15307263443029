import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Container } from '../../general/container/Container';

interface props {
  value: number;
  color?: string
}

export const RadialProgress = ({ value, color }: props) => {

  return (
    <Container width='100%' height='100%' display='flex' justify_content='center' align_items="center">
      <Container width='62%'>
        <CircularProgressbar value={value} text={`${value}%`} styles={{
          path: {
            stroke: (color ?? "#fff")
          },
          text: {
            fill: (color ?? "#fff")
          }
        }}/>
      </Container>
    </Container>
  );
};