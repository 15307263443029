import React from "react";
import { AppColors } from "../../../data/AppColors";
import { Widget } from "../../general/widget/Widget";
import { Container } from "../../general/container/Container";
import { Paragraph } from "../../general/paragraph/Paragraph";
import { RadialProgress } from "./RadialProgess";
import { AppLoadingStates } from "../../../data/AppLoadingStates";
import { validateEndpoint } from "../../../helper/EndpointValidation";
import { getDataForWidget } from "../Utils";
import { apiSettings, progressSettings } from "../../../interfaces/User";
import { Loading } from "../../general/loading/Loading";
import { isNumber } from "../../../helper/Helper";
import { useContrastTextColor } from "../../../hooks/FontColorPicker";

interface props {
    background: AppColors
    headline: string
    apiSettings?: apiSettings
    animation: boolean
    refreshingRate: number
    progressSettings: progressSettings
}

export const ProgressWidget = ({ background, headline, animation, refreshingRate, progressSettings, apiSettings }: props) => {

    const [lastUpdated, setLastUpdated] = React.useState<string>("00.00")
    const [value, setValue] = React.useState<number>(0)
    const [loading, setLoading] = React.useState<AppLoadingStates>(AppLoadingStates.LOADING)

    // this one is for re-rendering the widget
    const [animationKey, setAnimationKey] = React.useState<number>(0);

    const textColor = useContrastTextColor({ background });

    React.useEffect(() => {
        const getValue = async () => {
            if (!apiSettings || !(apiSettings.endpoint || apiSettings.headers)) return setLoading(AppLoadingStates.CONFIG_ERROR_ENDPOINT)

            const result = await getDataForWidget("/widgets/api/" + apiSettings.module, "POST", {
                endpoint: apiSettings.endpoint,
                responseKey: apiSettings.responseKey,
                widgettype: "ProgressComp",
                headers: apiSettings.headers
            })

            if (!result) return setLoading(AppLoadingStates.SERVER_ERROR)
            if (result === 450) return setLoading(AppLoadingStates.CONFIG_MISSING_PROP)
            if (result === 451) return setLoading(AppLoadingStates.CONFIG_ERROR_ENDPOINT)

            if (!value) setAnimationKey((prevKey) => prevKey + 1)
           
            const newValue = (result.data / progressSettings.target) * 100;
            setValue(parseFloat(newValue.toFixed(2)));
            setLoading(AppLoadingStates.SUCCESS)
            setLastUpdated(new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", second: "2-digit" }))
        }

        const intervalId = setInterval(() => {
            getValue()
        }, refreshingRate);

        getValue()

        return () => clearInterval(intervalId);
    }, [])

    return (
        <Widget background={background} animate={animation} height="100%" key={animationKey}>
            <Container display="flex" justify_content="center" flex_direction={true} padding="1em" height="50vh">
                <Container>
                    <Paragraph font_weight="600" text_align="center" color={textColor}>{headline}</Paragraph>
                </Container>
                <Container display="flex" justify_content="center" align_items="center" flex_direction={true} height="25vh">
                    {loading === AppLoadingStates.SUCCESS ? (
                        <Container>
                            <RadialProgress value={value} color={progressSettings.color} />
                        </Container>
                    ) : (
                        <Container>
                            <Paragraph font_weight="700" text_align="center" color={textColor}>{loading}</Paragraph>
                            <Loading />
                        </Container>
                    )}
                </Container>
                <Container margin="3vh 0 0 0">
                    <Paragraph font_weight="600" color={textColor} text_align="center">Ziel: {progressSettings.target}</Paragraph>
                </Container>
                <Container margin_top="auto" classname="refreshdate">
                    <Paragraph font_weight="600" text_align="center" classname="update" color={textColor}>Stand:</Paragraph>

                    <Paragraph font_weight="700" text_align="center" classname="update" color={textColor}>{lastUpdated} Uhr</Paragraph>
                </Container>
            </Container>
        </Widget>
    )
}