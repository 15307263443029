import React from "react";
import { Container } from "../container/Container";
import { Paragraph } from "../paragraph/Paragraph";

export const Loading = () => {

    return (
        <Container display="flex" justify_content="center">
            <span className="loading loading-spinner loading-sm"></span>
        </Container>
    )
}