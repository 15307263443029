import React from "react"

import styles from "./Widget.module.scss"
import { AppColors } from "../../../data/AppColors"
import { motion } from "framer-motion"

interface props {
    children: React.ReactNode
    padding?: string
    margin?: string
    width?: string
    height?: string
    border?: string
    cursor?: string
    background?: AppColors
    position?: any
    animate?: boolean
    borderRadius?: string
    overflow?: string
}

export const Widget = ({children, padding, margin, width, height, border, cursor, background, position, animate, borderRadius, overflow}: props) => {

    return (
        <>
        {animate ? (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                layout>
                <section className={styles.widget} style={{
            margin: margin,
            width: width,
            height: height,
            border: border,
            cursor: cursor,
            backgroundColor: background,
            position: position,
            overflow: overflow
        }}>
            
            <div className={styles.widget__content} style={{
                padding: padding
            }}>
                {children}
            </div>
        </section>
            </motion.div>
        ) : (
            <section className={styles.widget} style={{
                margin: margin,
                width: width,
                height: height,
                border: border,
                cursor: cursor,
                backgroundColor: background,
                position: position,
                borderRadius: borderRadius,
                overflow: overflow
            }}>
                
                <div className={styles.widget__content} style={{
                    padding: padding
                }}>
                    {children}
                </div>
            </section>
        )}
        </>
    )
}