import React, { Children } from "react";
import styles from "./Paragraph.module.scss"

interface props {
    children: React.ReactNode
    font_size?: string
    margin?: string
    color?: string
    font_weight?: string
    text_align?: any
    classname?: string
}

export const Paragraph = ({children, font_size, margin, color, font_weight, text_align, classname}: props) => {

    // return class for right media query for fontsize
    const getClass = (cname?: string): string => {
        switch (cname) {
            case 'value':
                return `${styles.valueparagraph}`;
            case 'text':
                    return `${styles.textparagraph}`;
            case 'update':
                return `${styles.updateparagraph}`;
            case 'list':
                return `${styles.listparagraph}`
            default:
                return '';
        }
    };

    return (
        <p style={{
            fontSize: font_size,
            margin: margin,
            color: color,
            fontWeight: font_weight,
            textAlign: text_align
        }} className={getClass(classname)}>
            {children}
        </p>
    )
}