import React from "react";

import styles from "./Label.module.scss"

interface props {
    children: React.ReactNode
    font_size?: string
    margin?: string
    color?: string
    font_weight?: string
}

export const Label = ({children, font_size, margin, color, font_weight}: props) => {

    return (
        <label style={{
            fontSize: font_size,
            margin: margin,
            color: color,
            fontWeight: font_weight
        }} className={styles.label}>
            {children}
        </label>
    )
}