import React from "react"
import { Container } from "../../components/general/container/Container"
import { Page } from "../../components/general/page/Page"
import { useForm } from "react-hook-form";
import { User } from "../../interfaces/User";
import { Label } from "../../components/general/label/Label";

import styles from "./LoginPage.module.scss"
import { Paragraph } from "../../components/general/paragraph/Paragraph";
import { AppLoadingStates } from "../../data/AppLoadingStates";
import { useNavigate } from "react-router-dom";
import { addToSessionStorage } from "../../helper/Sessionstorage";
import { login } from "./Utils";
import { isNumber } from "../../helper/Helper";

export const LoginPage = () => {

    const [status, setStatus] = React.useState<AppLoadingStates>(AppLoadingStates.NOTHING)
    const [dashboardName, setDashboardName] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');

    const navigate = useNavigate()

    const submitForm = async () => {
        const res = await login({
            dashboard_name: dashboardName,
            password: password
        })

        if (!res) return setStatus(AppLoadingStates.SERVER_ERROR)
        if (res === 500) return setStatus(AppLoadingStates.SERVER_ERROR)
        if (isNumber(res)) return setStatus(AppLoadingStates.LOGIN_FAIL)

        addToSessionStorage("showNavbar", false)
        setStatus(AppLoadingStates.LOGIN_SUCCESS)
        setTimeout(() => {
            navigate(`/${res.userId}/dashboard`)
        }, 3000)
    };

    return (
        <Page background="#fff" showNavbar={true}>
            <Container display="flex" justify_content="center" align_items="center" flex_direction={true}>
                <Container classname="bg-base-200" padding="2em" border_radius="10px" margin_top="6em">
                    <Container>
                        <Paragraph font_weight="700" margin="1.3em 0 1em 0" font_size="2.2em" text_align="center">Login</Paragraph>
                    </Container>

                    <Container display="flex" flex_direction={true} align_items="flex-start" margin="0 0 1.9em 0">
                        <Label font_size="1.2em" font_weight="600">Dashboardname</Label>
                        <input className={styles.loginpage__input}
                            value={dashboardName}
                            onChange={(e: any) => setDashboardName(e.target.value)} />
                    </Container>
                    <Container display="flex" flex_direction={true} align_items="flex-start">
                        <Label font_size="1.2em" font_weight="600">Passwort</Label>
                        <input className={styles.loginpage__input}
                            value={password}
                            onChange={(e: any) => setPassword(e.target.value)} type="password"/>
                    </Container>
                    <Container margin_top="2.2em" display="flex" justify_content="center">
                        <button className="btn btn-md btn-primary" onClick={() => submitForm()}>
                            <Paragraph font_size="1.2em">Anmelden</Paragraph>
                        </button>
                    </Container>
                    <Container margin_top="1.8em" display="flex" flex_direction={true} align_items="flex-start">
                        {status === AppLoadingStates.LOGIN_SUCCESS ? (
                            <Paragraph color="green">{status}</Paragraph>
                        ) : (
                            <Paragraph color="red">{status}</Paragraph>
                        )}
                    </Container>
                </Container>
            </Container>
        </Page>
    )
}