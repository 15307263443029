import { APIEndpoints } from "../../data/APIEndpoints";
import { server } from "../../data/AppSettings";
import { http } from "../../helper/Http";
import { User } from "../../interfaces/User";

export const login = async (config: any): Promise<any | number | null> => {
    const result: any = await http<User>({
        path: APIEndpoints.LOGIN,
        method: "POST",
        body: config
    });

    if (!result) {
        return null
    }

    if (result.ok && result.body) {
        return result.body.data;
    } else {
        return result.status;
    }
};