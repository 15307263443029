import React from "react";
import styles from "./Container.module.scss"
import { motion } from "framer-motion";

interface props {
    children: React.ReactNode
    margin?: string
    padding?: string
    width?: string
    justify_content?: string
    display?: string
    max_height?: string
    gap?: string
    height?: string
    flex_direction?: boolean // cant use string because ts doesnt want it
    align_items?: any
    border_radius?: string
    background?: string
    animate?: boolean
    white_space?: any
    flex_grow?: number
    overflow_y?: any
    margin_top?: string
    classname?: string
    overflow?: string
    textoverflow?: any
}

// only a container for storing widgets etc
// basically same as widget without background

export const Container = ({ children, margin, padding, width, justify_content, display, max_height, gap, height, flex_direction, align_items, border_radius, background, animate, white_space, flex_grow, overflow_y, margin_top, classname, overflow, textoverflow }: props) => {

    return (
        <>
            {animate ? (
                <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5 }}>
                    <div className={classname} style={{
                        margin: margin,
                        padding: padding,
                        width: width,
                        justifyContent: justify_content,
                        display: display,
                        maxHeight: max_height,
                        height: height,
                        gap: gap,
                        flexDirection: (flex_direction ? "column" : "row"),
                        alignItems: align_items,
                        borderRadius: border_radius,
                        backgroundColor: background,
                        whiteSpace: white_space,
                        flexGrow: flex_grow,
                        overflowY: overflow_y,
                        marginTop: margin_top,
                        overflow: "auto",
                        flexWrap: "wrap",
                        textOverflow: textoverflow,
                    }}>
                        {children}
                    </div>
                </motion.div>
            ) : (
                <div className={classname} style={{
                    margin: margin,
                    padding: padding,
                    width: width,
                    justifyContent: justify_content,
                    display: display,
                    maxHeight: max_height,
                    height: height,
                    gap: gap,
                    flexDirection: (flex_direction ? "column" : "row"),
                    alignItems: align_items,
                    borderRadius: border_radius,
                    backgroundColor: background,
                    whiteSpace: white_space,
                    flexGrow: flex_grow,
                    overflowY: overflow_y,
                    marginTop: margin_top,
                    overflow: overflow,
                    textOverflow: textoverflow
                }}>
                    {children}
                </div>
            )}
        </>
    )
}