import { APIEndpoints } from "../../data/APIEndpoints";
import { server } from "../../data/AppSettings";
import { http } from "../../helper/Http";
import { User, dashboardWidget } from "../../interfaces/User";

export const getUserById = async (userId: string): Promise<User | number | null> => {
    const queryString = new URLSearchParams({"userId":  userId}).toString();
    const result:any = await http<User>({
        path: `${APIEndpoints.GET_USER_BY_USERID}${queryString ? `?${queryString}` : ''}`
    });

    if(!result) {
        return null
    }

    if(result.ok && result.body) {
        return result.body.data;
    } else {
        return result.status;
    }
  };