import { useEffect, useState } from 'react';
import fontColorContrast from 'font-color-contrast';

interface UseContrastTextColorProps {
  background: string;
}

export const useContrastTextColor = ({ background }: UseContrastTextColorProps) => {
  const [textColor, setTextColor] = useState<string>('');

  useEffect(() => {
    const calculatedColor = fontColorContrast(background); // Berechnen der Textfarbe basierend auf dem Hintergrund
    setTextColor(calculatedColor);
  }, [background]);

  return textColor;
};

