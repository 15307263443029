export enum AppColors {
    WHITE = "#ffffff",
    BACKGROUND = "#f5f5f5",
    LIGHT_BLUE = "#b0e0e6",
    DARK_BLUE = "#468499",
    LIGHT_ORANGE = "#FFAC1C",
    DARK_ORANGE = "#FF7F50",
    LIGHT_GREEN = "#a0db8e",
    DARK_GREEN = "#065535",
    LIGHT_RED = "#ff7373",
    DARK_RED = "#990000",
    PURPLE = "#9F2B68",
    TURQUOIS = "#40E0D0",
    DARK_TORQUOIS = "#18B8A8",
    ROSA = "#ffb8c6"
}