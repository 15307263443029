export enum AppLoadingStates {
    LOADING = "Lade Daten...",
    LOADING_TIME = "Lade Daten, dies kann kurz dauern...",
    ERROR = "Es ist ein Fehler aufgetreten",
    SERVER_ERROR = "Bei der Datenverarbeitung ist ein Fehler aufgetreten. Wenden Sie sich bitte an digitalXL",
    SERVER_DOWN = "Konnte keine Verbindung zum Server aufbauen\nBitte überprüfen Sie ihre Verbindung. Falls diese in Takt ist, wenden sie sich an digitalXL",
    API_RESPONSE_MISSING = "Eine Antwort der Api ist fehlerhaft",
    CONFIG_MISSING_PROP = "In Ihrer Konfigurationsdatei fehlt ein Attribut - Bitte wenden Sie sich an digitalXL",
    CONFIG_ERROR_ENDPOINT = "Die ApiSettings in Ihrer Config sind fehlerhaft, bitte wenden sie sich an digitalXL",
    PLACETEL_ERROR = "Daten von Placetel sind nicht erreichbar",
    SUCCESS = "Erfolgreich",
    USERID_ERROR = "Konnte keine passende UserId bekommen",
    NOTHING = "",
    LOGIN_FAIL = "Ihre Anmeldedaten stimmen nicht, bitte versuche es erneut",
    LOGIN_SUCCESS = "Sie werden umgehend weitergeleitet",
    UNAUTHORIZED = "",
    USERNAME_ALREADY_EXISTS= "Dieser Nutzername existiert bereits"
}