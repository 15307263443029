import { http } from "../../helper/Http";

/**
 * Get data function used for all Widgets
 * 
 * Only type any, because can´t define what interface
 * to use everytime
 * 
 */

export const getDataForWidget = async (endpoint: string, method?: string, body?: any): Promise<any | any[] | null> => {
    const result:any = await http<any>({
        path: `${endpoint}`,
        method: method,
        body: body
    });

    if(!result || result.status === 500) {
        return null
    }
 
    if(result.ok && result.body) {
        if(endpoint.includes("Chart")) {
            // validate data
            for(var i = 0; i < result.body.data.length; i++) {
                if(!(result.body.data[i] as any).ok) {
                    return 408
                }
            }
        }
        return result.body;
    } else {
        
        return result.status;
    }
};
