import React from "react"
import { AppColors } from "../../../data/AppColors"
import { Widget } from "../../general/widget/Widget"
import { Container } from "../../general/container/Container"
import { Paragraph } from "../../general/paragraph/Paragraph"
import { useContrastTextColor } from "../../../hooks/FontColorPicker"
import { FormatDate } from "../../../helper/DateFormatter"

interface props {
    background: AppColors
    refreshingRate?: number
    source?: string
}

export const TimeWidget = ({background, refreshingRate, source}: props) => {

    const formatDate = (date: Date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1; 
        const year = date.getFullYear();
      
        return `${day}.${month}.${year}`;
    };

    const [time, setTime] = React.useState<string>(FormatDate(new Date()))
    const [formattedDate, setFormattedDate] = React.useState<string>(formatDate(new Date()))
    const [width, setWidth] = React.useState<string>("600")
    const [fontSize, setFontSize] = React.useState<string>("2em")

    React.useEffect(() => {
        const getTime = setInterval(() => {
            const now = new Date()
            setTime(now.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }))
            
        }, 60000)

        return () => clearInterval(getTime);
    }, [])

    React.useEffect(() => {
        const image:any = document.getElementById('sourceImage');
        if(!image) return

        image.onload = function() {
            const w = parseInt(image.width)

            if(w < 340) {
                setWidth(String(w * 3.3))
            } else if(w < 700) {
                setWidth(String(w / 1.9))
            } else if(w < 1000) {
                setWidth(String(w / 2.6))
            } else {
                setWidth(String(w / 3.4)) 
            }
        };

        setFontSize("1.3em")
    }, [])

    const textColor = useContrastTextColor({background});


    return (
        <Widget background={background}>
            <Container display="flex" flex_direction={true} height="50vh">
                <Container height="50%" display="flex" justify_content="center" align_items="center">
                    <img src={source} id="sourceImage" width={width}/>
                </Container>
                <Container display="flex" justify_content="center" height="50%" align_items="center" flex_direction={true}>
                    <Paragraph color={textColor} font_size="1.8em"font_weight="600">{time} Uhr</Paragraph>
                    <Paragraph color={textColor} font_size="0.9em"font_weight="500" margin="0.2em 0 0 0">{formattedDate}</Paragraph>
                </Container>
            </Container>
        </Widget>
    )
}