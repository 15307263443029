export const addToSessionStorage = (key: string, value: any): void => {
    try {
        const serializedValue = JSON.stringify(value);
        sessionStorage.setItem(key, serializedValue);
    } catch (error) {
        console.error('Fehler beim Hinzufügen zur sessionStorage:', error);
    }
};

export const removeFromSessionStorage = (key: string): void => {
    try {
        sessionStorage.removeItem(key);
    } catch (error) {
        console.error('Fehler beim Entfernen aus der sessionStorage:', error);
    }
};

export const getFromSessionStorage = (key: string): boolean => {
    try {
        const serializedValue = sessionStorage.getItem(key);
        return JSON.parse(serializedValue ?? 'false');
    } catch (error) {
        console.error('Fehler beim Abrufen aus der sessionStorage:', error);
        return false;
    }
};