import { AppColors } from "../../../data/AppColors"
import { Paragraph } from "../../general/paragraph/Paragraph"
import { Widget } from "../../general/widget/Widget"
import styles from "./Welcome.module.scss"

interface props {
    background: AppColors
}

export const WelcomeWidget = ({background}: props) => {

    return (
        <Widget background={background} padding="1.1em" height="100%">
            <div className={styles.welcomewidget}>
                <header>
                    Willkommen zu Ihrem Dashboard
                </header>
                <Paragraph color="#fff">
                    Sie haben die Auswahl zwischen vielen verschiedenen Widgets, welche wir Ihnen zu Verfügung stellen
                </Paragraph>
            </div>
        </Widget>
    )
}