// all these are without filter! filter are added manually 
// in each comp

export enum APIEndpoints {
    LOGIN = "/login",
    SIGNUP = "/signup",
    REPLACE = "/replace",
    GET_USER_BY_USERID = "/users", // need query params

    PLACETEL_GET_CALLS_NUMBER = "/placetel/calls",
    PLACETEL_GET_UNANSWERED_CALLS_NUMBER = "/placetel/calls/unanswered",
    PLACETEL_GET_LAST_CALLS = "/placetel/calls/last",

    XENTRAL_GET_REPORT = "/xentral/reports",
    XENTRAL_GET_RETURNS_NUMBER = "/xentral/returns/number",
    XENTRAL_GET_ORDERS_NUMBER = "/xentral/orders/number", // not
    XENTRAL_GET_OPEN_PICKS_NUMBER = "/xentral/picks/number", // not
    XENTRAL_GET_TOP_CUSTOMERS_BY_AMOUNT = "/xentral/customers/amount",
    XENTRAL_GET_TOP_CUSTOMERS_BY_SALES = "/xentral/customers/sales"
} 