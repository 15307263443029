import React from "react"
import { Grid } from "../../general/grid/Grid"
import { Widget } from "../../general/widget/Widget"
import styles from "./Grid1.module.scss"
import { Container } from "../../general/container/Container"
import { Paragraph } from "../../general/paragraph/Paragraph"

interface props {
    children: React.ReactNode
}

export const Grid1 = ({children}: props) => {

    const numberOfWidgets = 5;
    const [valid, setValid] = React.useState(false)

    React.useEffect(() => {
        const checkIfValid = () => {
            if (React.Children.toArray(children).filter(child => React.isValidElement(child) && child.type.toString().includes("color")).length === numberOfWidgets) {
                return setValid(true)
            } 
        }

        checkIfValid()
    },[])

    // need the extra div for applying own style class for eact grid
    return (
        <div className={styles.grid1}>
            {valid ? (
                <Grid>
                    {children} 
                </Grid>
            ) : (
                <Container display="flex" justify_content="center">
                    <Paragraph font_size="2.4em" font_weight="600" color="#fff">Error #5001: Not the exact amount of widgets for this Grid</Paragraph>
                </Container>
            )}
        </div>
    )
}