/**
 * Need this file for defining all Widgets into vars and saving them into array
 * 
 * Used for rendering in dashboardpage
 */

import React from "react";
import { TimeWidget } from "../components/widgets/time/TimeWidget";
import { AppColors } from "./AppColors";
import { WelcomeWidget } from "../components/widgets/welcome/WelcomeWidget";
import { ValueWidget } from "../components/widgets/value/ValueWidget";
import { AreaChartWidget } from "../components/widgets/area/AreaChartWidget";
import { ProgressWidget } from "../components/widgets/progress/ProgessWidget";
import { ImageWidget } from "../components/widgets/image/ImageWidget";
import { ListWidget } from "../components/widgets/list/ListWidget";
import { apiSettings, chartSettings, listSettings, kpiSettings, progressSettings, workSettings } from "../interfaces/User";
import { PlaceholderWidget } from "../components/widgets/placeholder/PlaceholderWidget";

const convertToColorEnum = (color: string): AppColors => {
    if(color in AppColors) {
        var colorEnum: AppColors = AppColors[color as keyof typeof AppColors]
        return colorEnum
    } 

    // should never happen
    return AppColors.DARK_RED   
}

const defaultRefreshingRate: number = 3600000// 1 hour in ms

// contains all widgets
export const AppWidgets: {[key: string]: (color: string, headline?: string, kpiSettings?: kpiSettings, apiSettings?: apiSettings, source?: string, listSettings?: listSettings, animation?: boolean, refreshingRate?: number, progressSettings?: progressSettings, chartSettings?: chartSettings, workSettings?: workSettings, suffix?: string, dateformat?: string) => JSX.Element} = {
    "TimeComp": (color, _, __, ___, source, ____, _____, refreshingRate) => <TimeWidget background={convertToColorEnum(color)} 
                refreshingRate={refreshingRate} 
                source={source}/>,
    "WelcomeComp": (color, _, __, ___, source, ____) => <WelcomeWidget background={convertToColorEnum(color)} 
                />,
    "ValueComp": (color, headline, kpiSettings, apiSettings, _, __, animation, refreshingRate, ___, _____, workSettings, suffix) => <ValueWidget background={convertToColorEnum(color)} 
                headline={headline ? headline : "Error"}  
                kpiSettings={kpiSettings ?? undefined} 
                apiSettings={apiSettings}
                animation={animation ? animation : false}
                refreshingRate={refreshingRate ? refreshingRate : defaultRefreshingRate}
                workSettings={workSettings ?? undefined} 
                suffix={suffix} />, 

    "AreaComp": (color, headline, _, apiSettings, ___, ____, animation, refreshingRate, _____, chartSettings) => <AreaChartWidget background={convertToColorEnum(color)} headline={headline ? headline : "Error"}  
                apiSettings={apiSettings}
                animation={animation ? animation : false} 
                refreshingRate={refreshingRate ? refreshingRate : defaultRefreshingRate}
                chartSettings={chartSettings ? chartSettings : undefined} />,

    "ProgessComp": (color, headline, _,  apiSettings,  ___,  ____, animation, refreshingRate, progressSettings, _____) => <ProgressWidget background={convertToColorEnum(color)} headline={headline ? headline : "Error"}  
                apiSettings={apiSettings}
                animation={animation ? animation : false} 
                refreshingRate={refreshingRate ? refreshingRate : defaultRefreshingRate}
                progressSettings={progressSettings ? progressSettings : { target: 100} }/>, 
    "ImageComp": (color, _, __, ___, source, ____, animation, refreshingRate) => <ImageWidget background={convertToColorEnum(color)} source={source ? source : ""}
                animation={animation ? animation : false}/>,
    "ListComp": (color, headline, _, apiSettings, __, settings, animation, refreshingRate, ___, ____, _____, _______, dateformat) => <ListWidget background={convertToColorEnum(color)} 
                headline={headline ? headline : "Error"}  
                apiSettings={apiSettings}
                listSettings={settings ?? {length: 5, attributes: []}}
                animation={animation ? animation : false} 
                dateformat={dateformat} />,
    "PlaceholderComp": (color) => <PlaceholderWidget background={convertToColorEnum(color)} />
}