import React from "react";
import { Page } from "../../components/general/page/Page";
import { User, dashboardWidget } from "../../interfaces/User";
import { Grid1 } from "../../components/grid/grid1/Grid1";
import { Grid2 } from "../../components/grid/grid2/Grid2";
import { AppWidgets } from "../../data/AppWidgets";
import { Grid3 } from "../../components/grid/grid3/Grid3";
import { getUserById } from "./Utils";
import { AppLoadingStates } from "../../data/AppLoadingStates";
import { Paragraph } from "../../components/general/paragraph/Paragraph";
import { Container } from "../../components/general/container/Container";
import { useNavigate, useParams } from "react-router-dom";
import { getFromSessionStorage } from "../../helper/Sessionstorage";
import { Grid4 } from "../../components/grid/grid4/Grid4";
import { APIEndpoints } from "../../data/APIEndpoints";
import { isNumber } from "../../helper/Helper";
import { Grid5 } from "../../components/grid/grid5/Grid5";

type ComponentPropsGrid = {
    gridname: string
    children: React.ReactNode
}

type allPropsFromWidgets = dashboardWidget

export const DashboardPage = () => {

    // userid from url
    const { userId } = useParams<{userId: string}>();

    const [user, setUser] = React.useState<User>()
    const [loading, setLoading] = React.useState<AppLoadingStates>(AppLoadingStates.LOADING)

    const navigate = useNavigate()

    React.useEffect(() =>  {
        const getUser = async () => {
            if(!userId) return setLoading(AppLoadingStates.USERID_ERROR)

            const result: any = await getUserById(userId)
            if(!result) return setLoading(AppLoadingStates.SERVER_DOWN)

            if(result === 500) return setLoading(AppLoadingStates.SERVER_ERROR)
            if(result === 401 || result === 404) return navigate("/login")
            if(isNumber(result)) return setLoading(AppLoadingStates.UNAUTHORIZED)

            setUser(result)
            setLoading(AppLoadingStates.SUCCESS)
        }

        getUser()
    }, [])

    // renders specific grid for user
    const GridSwitcher: React.FC<ComponentPropsGrid> = ({gridname, children}) => {
        switch(gridname) {
            case "grid1":
                return <Grid1>{children}</Grid1>
            case"grid2":
                return <Grid2>{children}</Grid2>
            case "grid3":
                return <Grid3>{children}</Grid3>
            case "grid4":
                return <Grid4>{children}</Grid4>
            case "grid5":
                return <Grid5>{children}</Grid5>
        }

        // this case should never happen, only for compiler
        return null
    }

    const RenderComponent = ({ color, widget, headline, kpiSettings, source, apiSettings, listSettings, animation, refreshingRate, progressSettings, chartSettings, workSettings, suffix, dateformat }: allPropsFromWidgets): JSX.Element => {
        if(widget === "ListComp") console.log("format", dateformat)
        const Component: any = AppWidgets[widget](color, headline, kpiSettings, apiSettings, source, listSettings, animation, refreshingRate, progressSettings, chartSettings, workSettings, suffix, dateformat);
        return Component;
    }

    return (
        <Page background="black" showNavbar={getFromSessionStorage("showNavbar")}>
            {loading === AppLoadingStates.SUCCESS ? (
                <GridSwitcher gridname={user?.grid!}>
                    {user?.dashboard.map((element, index) => (
                        <RenderComponent  color={element.color} 
                                        widget={element.widget} 
                                        headline={element.headline} 
                                        kpiSettings={element.kpiSettings} 
                                        source={element.source} 
                                        apiSettings={element.apiSettings} 
                                        listSettings={element.listSettings}
                                        animation={element.animation} 
                                        refreshingRate={element.refreshingRate} 
                                        progressSettings={element.progressSettings} 
                                        chartSettings={element.chartSettings} key={index} 
                                        workSettings={element.workSettings} 
                                        suffix={element.suffix}    
                                        dateformat={element.dateformat} />    
                                        
                    ))}
                </GridSwitcher>
            ) : (
                <Container display="flex" justify_content="center" margin="4em 0 0 0">
                    <Paragraph color="red" font_weight="600" text_align="center">{loading}</Paragraph>
                </Container>
            )}
        </Page>
        
    )
}